import {useCallback, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useCurrentProject, useProjectTimezone} from '@entities/project';
import {DeleteStatusReportDialog, useStatusReports} from '@entities/statusReport';
import {IconButton} from '@shared/ui/Button';
import {StatusReport} from '@shared-app/types';
import {DATE_FORMATS, ROUTES} from '@shared/lib/constants';
import {ColumnDef, SortItem, Table} from '@shared/ui/Table';
import {formatInTimeZone} from 'date-fns-tz';
import {TFunction} from 'i18next';
import {useTranslation} from 'react-i18next';
import {StatusReportListTableSkeleton} from './StatusReportListTableSkeleton';
import {ReportStatus} from './ReportStatus';
import {Project} from '@shared/types/Project';
import {GridRowClassNameParams, GridRowParams} from '@mui/x-data-grid';
import styles from './StatusReportListTable.module.scss';
import {DeleteIcon} from '@shared/ui/icons';
import {Tooltip} from '@shared/ui/Tooltip';
import {LanguageCode, LANGUAGES} from '@entities/LanguageSwitcher';

export function StatusReportListTable() {
  const {t} = useTranslation('statusReport');
  const navigate = useNavigate();
  const timezone = useProjectTimezone();
  const {project} = useCurrentProject();
  const {reports, isFetching} = useStatusReports();
  const [statusReportBeingDeleted, setStatusReportBeingDeleted] = useState<StatusReport>();
  const columns = useMemo(
    () => makeColumns(t, project, timezone, setStatusReportBeingDeleted),
    [t, project, timezone]
  );
  const [sortModel, setSortModel] = useState<SortItem[]>([{field: 'createdOn', sort: 'desc'}]);

  const closeReportDeletionDialog = useCallback(() => setStatusReportBeingDeleted(undefined), []);

  const handleRowClick = useCallback(
    ({row: statusReport}: GridRowParams<StatusReport>) => {
      if (statusReport.status !== 'completed') return;

      navigate(
        ROUTES.PROJECT.STATUS_REPORT.DETAIL.withParams({
          projectId: project.id,
          reportId: statusReport.id
        })
      );
    },
    [navigate, project.id]
  );

  const getRowClassName = useCallback(
    ({row: statusReport}: GridRowClassNameParams<StatusReport>) =>
      statusReport.status === 'completed' ? styles.clickable : styles.disabledRow,
    []
  );

  if (isFetching) {
    return <StatusReportListTableSkeleton />;
  }

  return (
    <>
      <Table
        getRowId={getRowId}
        rows={reports}
        columns={columns}
        disableRowSelectionOnClick
        hideFooter
        hideFooterSelectedRowCount
        hideFooterPagination
        onSortModelChange={setSortModel}
        sortModel={sortModel}
        onRowClick={handleRowClick}
        getRowClassName={getRowClassName}
      />
      {statusReportBeingDeleted && (
        <DeleteStatusReportDialog
          report={statusReportBeingDeleted}
          onSuccess={closeReportDeletionDialog}
          onClose={closeReportDeletionDialog}
        />
      )}
    </>
  );
}

function getRowId(report: StatusReport) {
  return report.id;
}

function makeColumns(
  t: TFunction,
  project: Project,
  timezone: string,
  onDelete: (s: StatusReport) => void
): ColumnDef[] {
  const formatDate = (date: Date) => formatInTimeZone(date, timezone, DATE_FORMATS.DATE_TIME);

  return [
    {
      field: 'name',
      headerName: t('Name'),
      flex: 1
    },
    {
      field: 'source',
      headerName: t('Source'),
      flex: 0.5,
      valueFormatter: cameraId => project.getCameraById(cameraId)?.name
    },
    {
      field: 'createdOn',
      headerName: t('Creation Date'),
      flex: 0.5,
      valueFormatter: formatDate
    },
    {
      field: 'reportedOn',
      headerName: t('Report Date'),
      flex: 0.5,
      valueFormatter: formatDate
    },
    {
      field: 'language',
      headerName: t('Language'),
      flex: 0.3,
      valueFormatter: (value: LanguageCode) => LANGUAGES.find(({code}) => code === value)?.label
    },
    {
      field: 'status',
      headerName: t('Status'),
      flex: 0.2,
      renderCell: ({row}: {row: StatusReport}) => <ReportStatus status={row.status} />
    },
    {
      field: '',
      align: 'center',
      flex: 0.2,
      sortable: false,
      renderCell: ({row: statusReport}) => (
        <Tooltip title={t('Delete')} placement="top">
          <IconButton
            onClick={event => {
              event.stopPropagation();
              onDelete(statusReport);
            }}
            color="primary"
          >
            <DeleteIcon className={styles.deleteIcon} />
          </IconButton>
        </Tooltip>
      )
    }
  ];
}
