import {SmartImage} from '@shared/types/SmartImage';
import {DialogCloseButton, DialogContainer, DialogContent, DialogTitle} from '@shared/ui/Dialog';
import {Chip} from '@shared/ui/Chip';
import {CameraIcon, ChevronLeftIcon, ChevronRightIcon} from '@shared/ui/icons';
import {SmartImageWithTags} from '../../smartImageWithTags';
import {BorderButton} from '@shared/ui/Button';
import styles from './SmartImageWithTagsModal.module.scss';

interface Props {
  onClose: () => void;
  image: SmartImage | undefined;
  cameraName: string;
  onPrev?: () => void;
  onNext?: () => void;
  isPrevDisabled?: boolean;
  isNextDisabled?: boolean;
}

export function SmartImageWithTagsModal({
  image,
  onClose,
  cameraName,
  onNext,
  onPrev,
  isNextDisabled,
  isPrevDisabled
}: Props) {
  return (
    <DialogContainer classes={{paper: styles.dialogPaper}} onClose={onClose} fullWidth open>
      <DialogTitle className={styles.title} variant="h5">
        <Chip icon={<CameraIcon />} label={cameraName} variant="outlined" />
        <DialogCloseButton onClick={onClose} />
      </DialogTitle>
      <DialogContent className={styles.content}>
        <div className={styles.inner}>
          {onPrev && (
            <BorderButton
              size="large"
              color="primary"
              onClick={onPrev}
              disabled={isPrevDisabled}
              className={styles.prevBtn}
            >
              <ChevronLeftIcon />
            </BorderButton>
          )}
          <SmartImageWithTags image={image} cameraName={cameraName} />
          {onNext && (
            <BorderButton
              size="large"
              color="primary"
              onClick={onNext}
              disabled={isNextDisabled}
              className={styles.nextBtn}
            >
              <ChevronRightIcon />
            </BorderButton>
          )}
        </div>
      </DialogContent>
    </DialogContainer>
  );
}
