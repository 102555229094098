import {Typography} from '@shared/ui/Typography';
import {IconButton} from '@shared/ui/Button';
import {SparklesOutlinedIcon} from '@shared/ui/icons';
import {isDev} from '@shared-app/lib';
import styles from './AssistantCard.module.scss';

interface AssistantCardProps {
  children: React.ReactNode;
  title: string;
  onAssistantClick: (text: string) => void;
}

export function AssistantCard({children, title, onAssistantClick}: AssistantCardProps) {
  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <Typography classes={styles.title} variant="h4">
          {title}
        </Typography>
        {isDev() && (
          <IconButton className={styles.explainBtn} onClick={() => onAssistantClick(title)}>
            <SparklesOutlinedIcon />
          </IconButton>
        )}
      </div>
      {children}
    </div>
  );
}
