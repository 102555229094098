import {useRuntime} from '@modules/chatbot/entities';
import {MyAssistant} from './MyAssistant';
import {AssistantRuntimeProvider} from '@assistant-ui/react';

export function ChatBotPage() {
  const runtime = useRuntime();
  return (
    <div>
      <AssistantRuntimeProvider runtime={runtime}>
        <MyAssistant />
      </AssistantRuntimeProvider>
    </div>
  );
}
