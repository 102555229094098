import {ApexOptions} from 'apexcharts';
import Chart, {Props as ApexChartProps} from 'react-apexcharts';
import styles from './AxisChart.module.scss';
import {Typography} from '@shared/ui/Typography';
import {useElementSize} from '@conxai/react-kit';
import classNames from 'classnames';

interface YAxis extends Omit<ApexYAxis, 'title'> {
  title?: React.ReactNode;
}

interface Legend extends Omit<ApexLegend, 'customLegendItems'> {
  customLegendItems: string[];
}

export interface AxisChartOptions extends Omit<ApexOptions, 'yaxis' | 'legend'> {
  yaxis?: YAxis;
  legend?: Legend;
}

export interface AxisChartSeriesItem {
  data: number[] | [number, number][];
  name: string;
}

export interface AxisChartProps {
  series: ApexAxisChartSeries;
  title?: string | React.ReactNode;
  options: AxisChartOptions;
  type?: ApexChartProps['type'];
  className?: string;
  classes?: {
    title?: string;
  };
}

export function AxisChart({series, title, options, type, classes, className}: AxisChartProps) {
  const {ref, width} = useElementSize<HTMLDivElement>();

  const {title: yaxisTitle, ...yaxisOptions} = options.yaxis || {};

  return (
    <div className={classNames(styles.container, className)}>
      {title && (
        <Typography classes={classNames(styles.title, classes?.title)} variant="h4">
          {title}
        </Typography>
      )}
      <div className={styles.chartContainer}>
        <div className={styles.yaxisTitle}>{yaxisTitle}</div>
        <div className={styles.chartWrapper} ref={ref}>
          <div className={styles.chartHeightFix}>
            <Chart
              type={type}
              series={series}
              options={getChartOptions(options, yaxisOptions)}
              width={width}
              height={320}
            />
          </div>
          <div className={styles.legend}>
            {options.legend?.customLegendItems.map((legendItem, index) => (
              <div className={styles.legendItem} key={legendItem}>
                <div
                  className={styles.marker}
                  style={{backgroundColor: options.colors[index]}}
                ></div>
                <div>{legendItem}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

function getChartOptions(
  options: AxisChartOptions,
  yaxisOptions: Omit<YAxis, 'title'>
): ApexOptions {
  const {
    labels: xaxisLabelOptions,
    title: xAxisTitleOptions,
    ...xaxisOptions
  } = options.xaxis || {};
  const {text: xAxisTitleText, ...extraXAxisTitleOptions} = xAxisTitleOptions || {};
  const {labels: yAxisLabels, ...extraYAxisOptions} = yaxisOptions;
  const {style: yAxisLabelStyles, ...yAxisLabelOptions} = yAxisLabels || {};
  return {
    ...options,
    states: {
      active: {
        filter: {
          type: 'none'
        }
      },
      hover: {
        filter: {
          type: 'none'
        }
      },
      ...options.states
    },
    chart: {
      ...options.chart,
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      }
    },
    legend: {
      show: false,
      customLegendItems: []
    },
    grid: {
      strokeDashArray: 4,
      padding: {
        right: 30
      }
    },
    stroke: {
      curve: 'smooth',
      width: 0,
      ...options.stroke
    },
    yaxis: {
      title: {text: ''},
      labels: {
        // below two options used with xaxis.labels.rotate to avoid clipping of x-axis first label
        offsetX: -10,
        minWidth: 20,
        formatter: (val, opts) => {
          if (opts?.series) {
            return val.toFixed(2);
          }

          // if `opts?.series` is undefined, it means `formatter` being used for y-axis tick labels
          // in that case round numbers instead of showing float values
          // `typeof val === 'number'` check required below because y-axis ticket labels can be strings (ex: heatmap)
          return typeof val === 'number' ? Math.round(val).toString() : val;
        },
        style: {
          colors: '#00000099',
          fontSize: '14px',
          fontFamily: 'Inter',
          ...yAxisLabelStyles
        },
        ...yAxisLabelOptions
      },
      ...extraYAxisOptions
    },
    tooltip: {
      hideEmptySeries: false,
      ...options.tooltip,
      style: {
        fontSize: '14px',
        fontFamily: 'Inter'
      }
    },
    xaxis: {
      title: {
        text: xAxisTitleText || '',
        offsetY: -10,
        ...extraXAxisTitleOptions
      },
      labels: {
        datetimeUTC: false,
        hideOverlappingLabels: false,
        rotateAlways: true,
        // default causes clipping of first label
        rotate: -60,
        offsetX: 5,
        minHeight: 60,
        style: {
          colors: '#00000099',
          fontSize: '14px',
          fontFamily: 'Inter'
        },
        ...xaxisLabelOptions
      },
      ...xaxisOptions,
      axisTicks: {
        show: false
      },
      axisBorder: {
        show: false
      }
    },
    dataLabels: {
      enabled: false,
      ...options.dataLabels
    }
  };
}
