import {useState} from 'react';
import {usePPEComplianceInfo} from '@entities/ppeDashboard';
import {ChartsSkeleton} from './ChartsSkeleton';
import {AbsoluteRelativeSelect, MeasuredAs} from '@features/ppeDashboard/AbsoluteRelativeSelect';
import {NonComplianceSummary} from '@features/ppeDashboard/NonComplianceSummary';
import {AttributeStatisticsPieChart} from '@features/ppeDashboard/AttributeStatisticsPieChart';
import {NonComplianceFrequencyByTimeHeatmap} from '@features/ppeDashboard/NonComplianceFrequencyByTimeHeatmap';
import {NonComplianceByDateChart} from '@features/ppeDashboard/NonComplianceByDateChart';
import {NonComplianceTypesByDateChart} from '@features/ppeDashboard/NonComplianceTypesByDateChart';
import {NonComplianceByWeekDayChart} from '@features/ppeDashboard/NonComplianceByWeekDayChart';
import {useSmartSearchFilter} from '@entities/smartSearchFilter';
import {useTranslation} from 'react-i18next';
import {useThreadRuntime} from '@assistant-ui/react';
import styles from './Charts.module.scss';

interface ChartsProps {
  showAssistant: () => void;
}

export function Charts({showAssistant}: ChartsProps) {
  const [measuredAs, setMeasuredAs] = useState(MeasuredAs.RELATIVE);
  const {t} = useTranslation('ppeDashboard');
  const {cameras, dateRange, timeRange, enabledAttributes} = useSmartSearchFilter();

  const thread = useThreadRuntime();

  const handleAssistantClick = (text: string) => {
    thread.composer.setText(`<<${text}>>`);
    thread.composer.send();
    showAssistant();
    setTimeout(() => {
      hideSystemTriggeredMessages();
    }, 10);
  };

  const {
    data: complianceInfo,
    isFetching,
    isError
  } = usePPEComplianceInfo({cameras, dateRange, timeRange, enabledAttributes});

  if (isFetching) {
    return <ChartsSkeleton />;
  }

  if (isError) {
    return 'Oops! Something went wrong';
  }

  if (cameras?.length === 0) {
    return <div className={styles.noCamera}>{t('Select at least one camera to see data')}</div>;
  }

  const isRelative = measuredAs === MeasuredAs.RELATIVE;

  return (
    <div className={styles.container}>
      <div className={styles.summary}>
        <NonComplianceSummary
          complianceInfo={complianceInfo}
          onAssistantClick={handleAssistantClick}
        />
        <AttributeStatisticsPieChart
          complianceInfo={complianceInfo}
          onAssistantClick={handleAssistantClick}
        />
      </div>
      <div className={styles.remainingCharts}>
        <AbsoluteRelativeSelect value={measuredAs} onChange={setMeasuredAs} />
        <div className={styles.charts}>
          <NonComplianceByDateChart
            complianceInfo={complianceInfo}
            isRelative={isRelative}
            onAssistantClick={handleAssistantClick}
          />
          <NonComplianceTypesByDateChart
            complianceInfo={complianceInfo}
            isRelative={isRelative}
            onAssistantClick={handleAssistantClick}
          />
          <NonComplianceByWeekDayChart
            complianceInfo={complianceInfo}
            isRelative={isRelative}
            onAssistantClick={handleAssistantClick}
          />
          <NonComplianceFrequencyByTimeHeatmap
            complianceInfo={complianceInfo}
            timeRange={timeRange}
            isRelative={isRelative}
            onAssistantClick={handleAssistantClick}
          />
        </div>
      </div>
    </div>
  );
}

function hideSystemTriggeredMessages() {
  const elements = document.querySelectorAll('.aui-user-message-root');
  elements.forEach((element: HTMLElement) => {
    if (element.textContent.startsWith('<<') && element.textContent.endsWith('>>')) {
      element.style.display = 'none';
    }
  });
}
