import {StatusReportDetails} from '@shared-app/types';
import {StatusReportImage} from './StatusReportImage';
import styles from './StatusReportImages.module.scss';
import {useState} from 'react';
import {StatusReportImageDialog} from './StatusReportImageDialog';

interface StatusReportImagesProps {
  report: StatusReportDetails;
}

export function StatusReportImages({report}: StatusReportImagesProps) {
  const [imageId, setImageToShow] = useState<string>();

  return (
    <div className={styles.images}>
      {report.images.map(imageId => (
        <StatusReportImage
          key={imageId}
          imageId={imageId}
          onClick={image => setImageToShow(image.imageId)}
        />
      ))}
      {imageId ? (
        <StatusReportImageDialog
          imageId={imageId}
          allImages={report.images}
          setActiveImage={setImageToShow}
        />
      ) : null}
    </div>
  );
}
