import {AssistantRuntimeProvider} from '@assistant-ui/react';
import {useRuntime} from '@modules/chatbot/entities';
import {Chatbot} from '@modules/chatbot/features';

export function Assistant() {
  const runtime = useRuntime();
  return (
    <AssistantRuntimeProvider runtime={runtime}>
      <Chatbot />
    </AssistantRuntimeProvider>
  );
}
