import {TagGroup as ITagGroup} from '@entities/tag/lib/types';
import {defaultTagRenderer, TagGroup} from '../TagGroup/TagGroup';
import {Tag} from '@shared/types/Tag';
import {plainToInstance} from 'class-transformer';
import TagModel from '@entities/tag/model/Tag.model';
import {TagEnum} from '@shared/types/TagEnum';
import {useCallback} from 'react';
import {partitionBy} from '@conxai/react-kit';
import {useSmartSearchFilter} from '@entities/smartSearchFilter';
import {TagTypeEnum} from '@shared/types/TagTypeEnum';
import {useTranslationTags} from '@shared/lib/hooks';
import {PPEEnabledTagAttrs} from '@entities/ppeDashboard';
import {PPEPersonTag} from './PPEPersonTag';

interface Props {
  group: ITagGroup;
  onTagClick: (t: Tag) => void;
}

export function PersonTagGroup({group, onTagClick}: Props) {
  const {t} = useTranslationTags();
  const {query, setTagsInQuery} = useSmartSearchFilter();

  const setSelectedTagId = useCallback(
    (selectedTagId: string) => {
      setTagsInQuery(tags => {
        const [personTags, otherTags] = partitionBy(tags, tag => tag.type === TagTypeEnum.person);
        const personTag = personTags[0];
        if (personTag?.key === selectedTagId) {
          return otherTags;
        } else {
          return [
            ...otherTags,
            plainToInstance(TagModel, {id: selectedTagId, type: TagTypeEnum.person})
          ];
        }
      });
    },
    [setTagsInQuery]
  );

  const makePPECompliantTagFromAttrs = useCallback(
    (attrs: PPEEnabledTagAttrs) => {
      const selectedTagId = getPPECompliantVariantTagFromAttrs(attrs);
      if (selectedTagId) {
        setSelectedTagId(selectedTagId);
      }
    },
    [setSelectedTagId]
  );

  const makePPEViolationTagFromAttrs = useCallback(
    (attrs: PPEEnabledTagAttrs) => {
      const selectedTagId = getPPEViolationVariantTagFromAttrs(attrs);
      if (selectedTagId) {
        setSelectedTagId(selectedTagId);
      }
    },
    [setSelectedTagId]
  );

  const renderPPEPersonTag = (tag: Tag) => {
    if (isPPETag(tag.key)) {
      const tagVariants = PPE_TAG_VARIANTS_MAP[tag.key];
      const initialSelectedVariation = tagVariants.find(tagKey =>
        query.tags.find(tag => tag.key === tagKey)
      );

      const extraProps =
        tag.key === TagEnum.person_ppe_compliant
          ? {
              onChange: makePPECompliantTagFromAttrs,
              popoverDescription: t(
                'other',
                'Person wearing all selected attributes is considered to be using PPE'
              )
            }
          : {
              onChange: makePPEViolationTagFromAttrs,
              popoverDescription: t(
                'other',
                'Person not wearing any of selected attributes is considered to be violating safety protocol'
              )
            };

      return (
        <PPEPersonTag
          key={tag.key}
          tag={tag}
          selectedTagId={initialSelectedVariation}
          {...extraProps}
        />
      );
    }

    return defaultTagRenderer(tag, tag => setSelectedTagId(tag.key));
  };

  return <TagGroup tagsGrouped={group} onTagClick={onTagClick} renderTag={renderPPEPersonTag} />;
}

export const PERSON_ONLY_GROUP = {
  type: TagTypeEnum.person,
  tags: plainToInstance(TagModel, [{id: TagEnum.person, type: TagTypeEnum.person}])
};

export const PERSON_GROUP = {
  type: TagTypeEnum.person,
  tags: plainToInstance(TagModel, [
    {id: TagEnum.person, type: TagTypeEnum.person},
    {id: TagEnum.person_ppe_compliant, type: TagTypeEnum.person},
    {id: TagEnum.person_ppe_violation, type: TagTypeEnum.person}
  ])
};

const PPE_TAG_VARIANTS_MAP = {
  [TagEnum.person_ppe_compliant]: [
    TagEnum.person_helmet,
    TagEnum.person_vest,
    TagEnum.person_helmet_vest
  ],
  [TagEnum.person_ppe_violation]: [
    TagEnum.person_no_helmet,
    TagEnum.person_no_vest,
    TagEnum.person_no_helmet_no_vest
  ]
};

function isPPETag(
  tagId: TagEnum
): tagId is TagEnum.person_ppe_compliant | TagEnum.person_ppe_violation {
  return [TagEnum.person_ppe_compliant, TagEnum.person_ppe_violation].includes(tagId);
}

function getPPECompliantVariantTagFromAttrs(attributes: PPEEnabledTagAttrs) {
  const [helmetValue, vestValue] = attributes;
  if (helmetValue && vestValue) {
    return TagEnum.person_helmet_vest;
  }

  if (helmetValue) {
    return TagEnum.person_helmet;
  }

  if (vestValue) {
    return TagEnum.person_vest;
  }

  return undefined;
}

function getPPEViolationVariantTagFromAttrs(attributes: PPEEnabledTagAttrs) {
  const [helmetValue, vestValue] = attributes;
  if (helmetValue && vestValue) {
    return TagEnum.person_no_helmet_no_vest;
  }

  if (helmetValue) {
    return TagEnum.person_no_helmet;
  }

  if (vestValue) {
    return TagEnum.person_no_vest;
  }

  return undefined;
}
