import {Typography} from '@shared/ui/Typography';
import {useTranslation} from 'react-i18next';
import styles from './NonComplianceSummary.module.scss';
import {TFunction} from 'i18next';
import {AttributeStatistics, ComplianceInfo} from '@entities/ppeDashboard';
import {getSummary} from '@entities/ppeDashboard/lib/utils/getSummary';
import {TagEnum} from '@shared/types/TagEnum';
import {formatPercentage} from '@conxai/react-kit';
import {AssistantCard} from '@shared-app/ui';

interface Props {
  complianceInfo: ComplianceInfo[];
  onAssistantClick: (text: string) => void;
}

export function NonComplianceSummary({complianceInfo, onAssistantClick}: Props) {
  const {
    statistics,
    statisticsInPercentage,
    mostCommonIssueNonComplPercentage,
    mostCommonIssueType,
    total
  } = getSummary(complianceInfo);

  const {t} = useTranslation('ppeDashboard');

  const mostCommonIssueLabel = getIssueTypeLabel(mostCommonIssueType, t);
  return (
    <AssistantCard
      title={t('PPE Compliance for selected period')}
      onAssistantClick={onAssistantClick}
    >
      <div className={styles.info}>
        <div>
          <Typography variant="subtitle1">{t('Compliance Rate')}</Typography>
          <div className={styles.complianceRate}>
            {formatPercentage(statisticsInPercentage.person_helmet_vest)}
          </div>
          <Typography classes={styles.subtext} variant="subtitle2">
            {t('{{complianceHours}} from {{totalHours}} man-hours', {
              complianceHours: statistics.person_helmet_vest,
              totalHours: total
            })}
          </Typography>
        </div>
        <div>
          <Typography variant="subtitle1">{t('Most common non-compliance type')}</Typography>
          <div className={styles.mostCommonIssueLabel}>{mostCommonIssueLabel}</div>
          <Typography classes={styles.subtext} variant="subtitle2">
            {t('{{mostCommonIssueNonComplPercentage}} from all non-compliances man-hours', {
              mostCommonIssueNonComplPercentage: formatPercentage(mostCommonIssueNonComplPercentage)
            })}
          </Typography>
        </div>
      </div>
    </AssistantCard>
  );
}

function getIssueTypeLabel(issueType: keyof AttributeStatistics, t: TFunction) {
  if (issueType === TagEnum.person_no_helmet) {
    return t('Not wearing a Helmet');
  }

  if (issueType === TagEnum.person_no_vest) {
    return t('Not wearing a Vest');
  }

  if (issueType === TagEnum.person_no_helmet_no_vest) {
    return t('No PPE');
  }

  return '';
}
