import {SmartImageThumbnail, useSmartImage} from '@entities/smartImage';
import {StatusReportImageSkeleton} from './StatusReportImageSkeleton';
import {SmartImage} from '@shared/types/SmartImage';
import {useProjectTimezone} from '@entities/project';
import {formatInTimeZone} from 'date-fns-tz';
import {DATE_FORMATS} from '@shared/lib/constants';
import styles from './StatusReportImage.module.scss';

interface StatusReportImageProps {
  imageId: string;
  onClick: (i: SmartImage) => void;
}

export function StatusReportImage({imageId, onClick}: StatusReportImageProps) {
  const {data: image, isFetching} = useSmartImage(imageId);

  const timezone = useProjectTimezone();

  if (isFetching) {
    return <StatusReportImageSkeleton />;
  }

  return (
    <div className={styles.container}>
      <span className={styles.time}>
        {formatInTimeZone(image.dateTime, timezone, DATE_FORMATS.TIME_24)}
      </span>
      <div>
        <SmartImageThumbnail className={styles.image} image={image} onClick={onClick} />
      </div>
    </div>
  );
}
