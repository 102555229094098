export const PHASE_NAMES = [
  'Preparation',
  'Shell - Lower',
  'Shell - Unsling',
  'Shell - Connection',
  'Ring - Setup',
  'Ring - Slinging',
  'Ring - Hoisting',
  'Idle'
];
