import {useCallback} from 'react';
import {useProjectTimezone} from '@entities/project';
import {useProductionTrackingFilters} from '../hooks/useProductionTrackingFilters';
import {DateRange, DateTimeInput} from '@shared/ui/Date';
import {useDateLocale} from '@shared-app/lib';
import {useTranslation} from 'react-i18next';
import {isAfter} from 'date-fns';
import styles from './ProductionTrackingFilters.module.scss';
import {TFunction} from 'i18next';

interface ProductionTrackingFiltersProps {
  allowedDateRange: DateRange;
}

export function ProductionTrackingFilters({allowedDateRange}: ProductionTrackingFiltersProps) {
  const {dateRange, setDateRange} = useProductionTrackingFilters();
  const {startDate, endDate} = dateRange;
  const {t} = useTranslation();
  const locale = useDateLocale();

  const handleStartDateChange = useCallback(
    (startDate: Date) => {
      const endDateValidated = isAfter(startDate, endDate) ? allowedDateRange.endDate : endDate;
      setDateRange({startDate, endDate: endDateValidated});
    },
    [endDate, allowedDateRange.endDate, setDateRange]
  );

  const timezone = useProjectTimezone();
  return (
    <div className={styles.filters}>
      <DateTimeInput
        label={t('Start')}
        value={startDate}
        timezone={timezone}
        onChange={handleStartDateChange}
        minDateTime={allowedDateRange.startDate}
        maxDateTime={allowedDateRange.endDate}
        locale={locale}
        validationErrorMap={getValidationMessageMap(t)}
      />
      <DateTimeInput
        label={t('End')}
        value={endDate}
        timezone={timezone}
        onChange={endDate => setDateRange({startDate, endDate})}
        minDate={startDate}
        minDateTime={startDate}
        maxDateTime={allowedDateRange.endDate}
        locale={locale}
      />
    </div>
  );
}

function getValidationMessageMap(t: TFunction) {
  return {
    maxTime: t('Selected time is greater than max allowed time', {ns: 'translation'})
  };
}
