import {Filters} from '@features/ppeDashboard/Filters';
import {Charts} from '@widgets/ppeDashboard';
import {AssistantRuntimeProvider} from '@assistant-ui/react';
import {Chatbot} from '@modules/chatbot/features';
import {useRuntime} from '@modules/chatbot/entities';
import styles from './PPEDashboardPage.module.scss';
import {useBoolean} from '@conxai/react-kit';

export function PPEDashboardPage() {
  const runtime = useRuntime();
  const [shouldShowAssistant, , showAssistant, hideAssistant] = useBoolean();

  return (
    <AssistantRuntimeProvider runtime={runtime}>
      <div className={styles.container}>
        <Filters />
        <Charts showAssistant={showAssistant} />
      </div>
      <Chatbot open={shouldShowAssistant} onClose={hideAssistant} />
    </AssistantRuntimeProvider>
  );
}
