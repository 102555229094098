import {Expose} from 'class-transformer';
import {StatusReport} from '@shared-app/types';
import {StatusReportStatuses} from '@shared-app/types/StatusReportStatuses';

export class StatusReportModel implements StatusReport {
  @Expose({name: 'id'})
  public id: string;

  @Expose({name: 'name'})
  public name: string;

  @Expose({name: 'source'})
  public source: string;

  @Expose({name: 'notes'})
  public notes: string;

  @Expose({name: 'created_at'})
  public createdOn: Date;

  @Expose({name: 'end_time'})
  public reportedOn: Date;

  @Expose({name: 'language'})
  public language: string;

  @Expose({name: 'status'})
  public status: StatusReportStatuses;
}
