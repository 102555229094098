import {ExpandIcon, CollapseIcon, ChatbotAvatarIcon} from '@modules/chatbot/entities';
import styles from './Chatbot.module.scss';
import {Typography} from '@shared/ui/Typography';
import {useTranslation} from 'react-i18next';
import {IconButton} from '@shared/ui/Button';
import {CloseIcon, SparklesIcon} from '@shared/ui/icons';
import {useEffect, useState} from 'react';
import classNames from 'classnames';
import {MyAssistant} from '@pages/chatbot/ui/MyAssistant';
import {ViewType} from '../lib/constants/ViewType';

interface ChatbotProps {
  open?: boolean;
  onClose?: () => void;
}

export function Chatbot({open, onClose}: ChatbotProps) {
  const {t} = useTranslation('chatbot');
  const [viewType, setViewType] = useState<ViewType>(open ? ViewType.COMPACT : undefined);

  useEffect(() => {
    if (open) {
      setViewType(ViewType.COMPACT);
    }
  }, [open]);

  useEffect(() => {
    if (viewType === undefined) {
      onClose?.();
    }
  }, [onClose, viewType]);

  return (
    <div
      className={classNames(styles.container, {
        [styles.container__fullscreen]: viewType === ViewType.FULLSCREEN,
        [styles.container__visible]: !!viewType
      })}
    >
      <div className={styles.inner}>
        <div className={styles.header}>
          <div className={styles.title}>
            <SparklesIcon />
            <Typography variant="h4">{t('How can I help you ?')}</Typography>
          </div>
          <div>
            <IconButton
              onClick={() =>
                setViewType(
                  viewType == ViewType.FULLSCREEN ? ViewType.COMPACT : ViewType.FULLSCREEN
                )
              }
            >
              {viewType === ViewType.FULLSCREEN ? (
                <CollapseIcon className={styles.rightActionIcon} />
              ) : (
                <ExpandIcon className={styles.rightActionIcon} />
              )}
            </IconButton>
            <IconButton onClick={() => setViewType(undefined)}>
              <CloseIcon className={styles.rightActionIcon} />
            </IconButton>
          </div>
        </div>
        <div className={styles.content}>
          <MyAssistant />
          {viewType === ViewType.FULLSCREEN && (
            <div className={styles.aimistake}>
              {t('Our AI can make mistakes. Please verify critical information.')}
            </div>
          )}
        </div>
      </div>
      <button
        className={classNames(styles.openIcon, {[styles.openIcon__hidden]: !!viewType})}
        onClick={() => setViewType(ViewType.COMPACT)}
      >
        <ChatbotAvatarIcon className={styles.avatar} />
        {t('Ask me a question')}
      </button>
    </div>
  );
}
