import {LangChainMessage, useLangGraphRuntime} from '@assistant-ui/react-langgraph';
import {useCurrentProject} from '@entities/project';
import {createThread, sendMessage, getThreadState} from '@pages/chatbot/lib/utils/chatApi';
import {useRef} from 'react';

export function useRuntime() {
  const threadIdRef = useRef<string | undefined>();
  const {project} = useCurrentProject();
  const runtime = useLangGraphRuntime({
    threadId: threadIdRef.current,
    stream: async messages => {
      if (!threadIdRef.current) {
        const {thread_id} = await createThread();
        threadIdRef.current = thread_id;
      }
      const threadId = threadIdRef.current;

      const updatedMessages = messages.map(message => {
        let {content} = message;
        if (typeof content === 'string') {
          content = /<<(.*)>>/.exec(content)?.[1] || content;
        }
        return {
          ...message,
          content: content
        } as LangChainMessage;
      });

      return sendMessage({
        threadId,
        messages: updatedMessages,
        projectId: project.id,
        timezone: project.settings.location.timezone
      });
    },
    onSwitchToNewThread: async () => {
      const {thread_id} = await createThread();
      threadIdRef.current = thread_id;
    },
    onSwitchToThread: async threadId => {
      const state = await getThreadState(threadId);
      threadIdRef.current = threadId;
      return {messages: state.values.messages};
    }
  });

  return runtime;
}
