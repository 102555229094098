import {useCurrentProject} from '@entities/project';
import {useSmartImage} from '@entities/smartImage';
import {SmartImageWithTagsModal} from '@features/smartImage/smartImageWithTagsModal';
import {CircularLoader} from '@shared/ui/Loader';

interface StatusReportImageDialogProps {
  imageId: string;
  allImages: string[];
  setActiveImage: (i: string) => void;
}

export function StatusReportImageDialog({
  imageId,
  allImages,
  setActiveImage
}: StatusReportImageDialogProps) {
  const {data: image, isFetching} = useSmartImage(imageId);
  const {project} = useCurrentProject();

  const currentImageIndex = allImages.findIndex(image => image === imageId);
  const isPrevDisabled = currentImageIndex === 0;
  const isNextDisabled = currentImageIndex === allImages.length - 1;

  const handlePrevious = () => {
    if (isPrevDisabled) return;
    setActiveImage(allImages[currentImageIndex - 1]);
  };

  const handleNext = () => {
    if (isNextDisabled) return;
    setActiveImage(allImages[currentImageIndex + 1]);
  };

  if (isFetching) {
    return (
      <div>
        <CircularLoader />
      </div>
    );
  }

  return (
    <SmartImageWithTagsModal
      image={image}
      onClose={() => setActiveImage(undefined)}
      cameraName={project.getCameraById(image.cameraId)?.name}
      onPrev={handlePrevious}
      onNext={handleNext}
      isPrevDisabled={isPrevDisabled}
      isNextDisabled={isNextDisabled}
    />
  );
}
