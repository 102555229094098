import {SavedSearch} from '@shared/types/SavedSearch';
import {SmartSearchFilterState} from '../state/useSmartSearchFilter';
import {Tag} from '@shared/types/Tag';
import {Milestone} from '@shared/types/Milestone';
import {SearchTimeRange} from '@shared/types/Search';
import {DateRange, PRESET_FACTORY_MAP} from '@shared/ui/Date';
import {TagOperator} from '../types/searchQuery';
import {getEnumFromStringValue} from '@conxai/react-kit';
import {makeSearchQueryFromRequestQuery} from '@shared-app/lib';
import {ViewType} from '@features/cameraListViewSwitch';
import {PPETagAttrs} from '@entities/ppeDashboard/types/ppeAttributes';

const getDateRange = (timeRange: SearchTimeRange, milestones: Milestone[]): DateRange => {
  const {relativeDateRange} = timeRange;
  if (relativeDateRange) {
    return PRESET_FACTORY_MAP[relativeDateRange](new Date());
  }
  const {startMilestoneId, endMilestoneId} = timeRange.getMilestoneRange();
  const {startDate: customStartDate, endDate: customEndDate} = timeRange.getDateRange();

  const startMilestone = startMilestoneId
    ? milestones.find(milestone => milestone.id === startMilestoneId)
    : undefined;
  const endMilestone = endMilestoneId
    ? milestones.find(milestone => milestone.id === endMilestoneId)
    : undefined;

  return {
    startDate: startMilestone?.date || customStartDate,
    endDate: endMilestone?.date || customEndDate
  };
};

export const getFiltersFromSavedSearch = (
  savedSearch: SavedSearch,
  allTags: Tag[],
  milestones: Milestone[]
): SmartSearchFilterState => {
  const {data: savedSearchData} = savedSearch;
  const {timeRange, query = {}} = savedSearchData;

  const operatorStr = Object.entries(query).filter(([, value]) => !!value)[0]?.[0];
  const operator = getEnumFromStringValue(TagOperator, operatorStr) || TagOperator.And;
  const tagIds = query[operator]?.map(makeSearchQueryFromRequestQuery) || [];

  const tags = tagIds.map(tagKey => allTags.find(tag => tag.key === tagKey));

  return {
    query: {operator, tags},
    viewType: ViewType.IMAGES,
    currentSavedSearchId: savedSearch.id,
    lastSavedSearchId: undefined,
    cameras: savedSearchData.cameras,
    dateRange: getDateRange(savedSearchData.timeRange, milestones),
    milestoneRange: savedSearchData.timeRange.getMilestoneRange(),
    timeRange: savedSearchData.timeRange.getHourRange(),
    dateRangePreset: timeRange.relativeDateRange,
    enabledAttributes: [PPETagAttrs.HELMET, PPETagAttrs.VEST]
  };
};
