import {useLogs} from '@modules/productionTracking/entities';
import styles from './ProductionTrackingLogs.module.scss';
import {useTranslation} from 'react-i18next';
import {useMemo} from 'react';
import {useProjectTimezone} from '@entities/project';
import {TFunction} from 'i18next';
import {DATE_FORMATS} from '@shared/lib/constants';
import {formatInTimeZone} from 'date-fns-tz';
import {ColumnDef, Table} from '@shared/ui/Table';
import {Logs} from '@modules/productionTracking/entities/lib/types/Logs';

const PAGE_SIZE = 100;

export function ProductionTrackingLogs() {
  const {data, isFetching} = useLogs();
  const timezone = useProjectTimezone();
  const {t} = useTranslation('productionTracking');

  const columns = useMemo(() => makeColumns(t, timezone), [t, timezone]);

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <Table
          getRowId={getRowId}
          rows={data}
          columns={columns}
          loading={isFetching}
          disableRowSelectionOnClick
          hideFooterSelectedRowCount
          classes={{columnHeader: styles.header, root: styles.table}}
          paginationMode="client"
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: PAGE_SIZE
              }
            }
          }}
          pageSizeOptions={[PAGE_SIZE]}
        />
      </div>
    </div>
  );
}

function getRowId(log: Logs) {
  return (
    log.startTime.getTime() +
    log.endTime.getTime() +
    log.grossDuration +
    log.phase +
    log.netDuration +
    log.ringId
  );
}

function makeColumns(t: TFunction, timezone: string): ColumnDef[] {
  const formatDate = (date: Date) =>
    `${formatInTimeZone(date, timezone, DATE_FORMATS.DATE)} ${formatInTimeZone(
      date,
      timezone,
      DATE_FORMATS.TIME_24
    )}`;

  return [
    {
      field: 'ringId',
      headerName: t('Ring ID'),
      flex: 1
    },
    {
      field: 'phase',
      headerName: t('Phase'),
      flex: 2
    },
    {
      field: 'startTime',
      headerName: t('Start time', {ns: 'translation'}),
      valueFormatter: formatDate,
      flex: 3
    },
    {
      field: 'endTime',
      headerName: t('End time', {ns: 'translation'}),
      valueFormatter: formatDate,
      flex: 3
    },
    {
      field: 'netDuration',
      headerName: t('Net duration'),
      flex: 1
    },
    {
      field: 'grossDuration',
      headerName: t('Gross duration'),
      flex: 2
    },
    {
      field: 'downtime',
      headerName: t('Downtime'),
      flex: 1
    }
  ];
}
