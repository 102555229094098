import {useQuery} from '@tanstack/react-query';
import {PPEDashboardService} from '../lib/services/ppeDashboard.service';
import {useEffect} from 'react';
import {ToastService} from '@shared/lib/services';
import {useTranslation} from 'react-i18next';
import {useCurrentProject, useProjectTimezone} from '@entities/project';
import {DateRange} from '@shared/ui/Date';
import {TimeRange} from '@shared/ui/TimeRange';
import {plainToInstance} from 'class-transformer';
import ComplianceInfoModel from '../lib/model/ComplianceInfo.model';
import {getHourRangeFromTimeRange} from '@shared-app/lib';
import {PPEEnabledTagAttrs} from '../types/ppeAttributes';
import {ComplianceInfo} from '../types/ComplianceInfo';

export function usePPEComplianceInfo({
  cameras,
  dateRange,
  timeRange,
  enabledAttributes
}: {
  cameras: string[];
  dateRange: DateRange;
  timeRange: TimeRange;
  enabledAttributes: PPEEnabledTagAttrs;
}) {
  const {t} = useTranslation();
  const {project} = useCurrentProject();
  const timezone = useProjectTimezone();
  const {data, isFetching, error, isError} = useQuery({
    queryKey: ['get-ppe-compliance', project.id, cameras, dateRange, timezone],
    queryFn: () =>
      PPEDashboardService.getComplianceInfo(
        project.id,
        {
          cameras,
          dateRange
        },
        timezone
      )
  });

  useEffect(() => {
    if (error?.message) {
      ToastService.error(t('failed to fetch non compliance info'));
    }
  }, [error?.message, t]);

  const [startHour, endHour] = getHourRangeFromTimeRange(timeRange);
  const filteredData = data?.map(complianceInfo => {
    const updatedCompliance = {
      ...complianceInfo,
      noHelmet: adjustNoHelmetBasedOnEnabledAttrs(complianceInfo, enabledAttributes).filter(
        (workDuration, hour) => isWithinHourRange(hour, startHour, endHour)
      ),
      noPPE: complianceInfo.noPPE.filter((workDuration, hour) =>
        isWithinHourRange(hour, startHour, endHour)
      ),
      noVest: adjustNoVestBasedOnEnabledAttrs(complianceInfo, enabledAttributes).filter(
        (workDuration, hour) => isWithinHourRange(hour, startHour, endHour)
      ),
      ppe: adjustPPEBasedOnEnabledAttrs(complianceInfo, enabledAttributes).filter(
        (workDuration, hour) => isWithinHourRange(hour, startHour, endHour)
      )
    };

    return plainToInstance(ComplianceInfoModel, updatedCompliance, {ignoreDecorators: true});
  });

  return {data: filteredData, isFetching, isError};
}

function isWithinHourRange(hourToCheck: number, startHour: number, endHour: number) {
  return hourToCheck >= startHour && hourToCheck < endHour;
}

function adjustPPEBasedOnEnabledAttrs(
  complianceInfo: ComplianceInfo,
  enabledAttributes: PPEEnabledTagAttrs
) {
  const [helmet, vest] = enabledAttributes;

  const valuesToAdd = !helmet ? complianceInfo.noHelmet : !vest ? complianceInfo.noVest : undefined;

  return valuesToAdd
    ? complianceInfo.ppe.map((value, index) => value + valuesToAdd[index])
    : complianceInfo.ppe;
}

function adjustNoVestBasedOnEnabledAttrs(
  complianceInfo: ComplianceInfo,
  enabledAttributes: PPEEnabledTagAttrs
) {
  const [, vest] = enabledAttributes;

  return !vest ? complianceInfo.noVest.map(() => 0) : complianceInfo.noVest;
}

function adjustNoHelmetBasedOnEnabledAttrs(
  complianceInfo: ComplianceInfo,
  enabledAttributes: PPEEnabledTagAttrs
) {
  const [helmet] = enabledAttributes;

  return !helmet ? complianceInfo.noHelmet.map(() => 0) : complianceInfo.noHelmet;
}
