import {
  DateTimePicker,
  DateTimePickerProps,
  DateTimePickerSlotsComponentsProps
} from '@mui/x-date-pickers/DateTimePicker';
import {LocalizationProvider, PickersActionBarProps} from '@mui/x-date-pickers';
import {useCallback, useMemo, useState} from 'react';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {TextField} from '@shared/ui/TextField';
import {Locale} from 'date-fns';
import {utcToZonedTime, zonedTimeToUtc} from 'date-fns-tz';
import {useBoolean} from '@conxai/react-kit';
import {CalendarTodayIcon} from '@shared/ui/icons';
import {Button, DialogActions} from '@mui/material';
import {FormHelperText} from '@shared/ui/Form';
import styles from './DateTimeInput.module.scss';
import {useTranslation} from 'react-i18next';

interface DateTimeInputProps extends Omit<DateTimePickerProps<Date>, 'onChange'> {
  timezone?: string;
  value: Date;
  onChange: (d: Date) => void;
  onAccept?: (d: Date) => void;
  onLeftActionClick?: () => void;
  leftActionLabel?: string;
  locale?: Locale;
  validationErrorMap?: Record<string, string>;
}

const TIME_STEPS = {minutes: 1};

export function DateTimeInput({
  timezone,
  value,
  onChange,
  label,
  maxDate,
  minDate,
  minDateTime,
  maxDateTime,
  onLeftActionClick,
  leftActionLabel,
  locale,
  onAccept,
  validationErrorMap,
  ...restProps
}: DateTimeInputProps) {
  const [validationError, setValidationError] = useState<string>();
  const [shouldOpenPicker, , openPicker, closePicker] = useBoolean();

  const zonedValue = useMemo(() => getZonedDateIfNonEmpty(value, timezone), [value, timezone]);

  const maxDateZoned = useMemo(
    () => getZonedDateIfNonEmpty(maxDate, timezone),
    [maxDate, timezone]
  );

  const minDateZoned = useMemo(
    () => getZonedDateIfNonEmpty(minDate, timezone),
    [minDate, timezone]
  );

  const minDateTimeZoned = useMemo(
    () => getZonedDateIfNonEmpty(minDateTime, timezone),
    [minDateTime, timezone]
  );

  const maxDateTimeZoned = useMemo(
    () => getZonedDateIfNonEmpty(maxDateTime, timezone),
    [maxDateTime, timezone]
  );

  const handleChange: DateTimePickerProps<Date>['onChange'] = useCallback(
    (value: Date, error) => {
      if (error.validationError) {
        setValidationError(validationErrorMap?.[error.validationError]);
        return;
      }
      if (timezone) {
        value = zonedTimeToUtc(value, timezone);
      }
      onChange(value);
    },
    [onChange, timezone, validationErrorMap]
  );

  const slotProps = useMemo(
    () =>
      ({
        actionBar: {
          className: styles.actionBar,
          onLeftActionClick,
          leftActionLabel
        },
        textField: {
          onClick: openPicker,
          variant: 'filled',
          className: styles.dateTextfield,
          inputProps: {
            className: styles.dateInput,
            disableUnderline: true
          }
        }
      }) as DateTimePickerSlotsComponentsProps<Date>,
    [openPicker, onLeftActionClick, leftActionLabel]
  );
  const slots = useMemo(
    () => ({textField: TextField, openPickerIcon: CalendarTodayIcon, actionBar: ActionBar}),
    []
  );

  return (
    <LocalizationProvider adapterLocale={locale} dateAdapter={AdapterDateFns}>
      <div>
        <DateTimePicker
          open={shouldOpenPicker}
          onClose={closePicker}
          label={label}
          slotProps={slotProps}
          onChange={handleChange}
          slots={slots}
          value={zonedValue}
          maxDate={maxDateZoned}
          minDate={minDateZoned}
          ampm={false}
          minDateTime={minDateTimeZoned}
          maxDateTime={maxDateTimeZoned}
          timeSteps={TIME_STEPS}
          onAccept={onAccept}
          {...restProps}
        />
        {validationError && (
          <FormHelperText className={styles.errorText} error>
            {validationError}
          </FormHelperText>
        )}
      </div>
    </LocalizationProvider>
  );
}

function getZonedDateIfNonEmpty(date: Date, timezone?: string) {
  return timezone && date ? utcToZonedTime(date, timezone) : date;
}

interface ActionBarProps extends PickersActionBarProps {
  leftActionLabel: string;
  onLeftActionClick: () => void;
}

function ActionBar(props: ActionBarProps) {
  const {className, onAccept, leftActionLabel, onLeftActionClick} = props;
  const {t} = useTranslation();

  return (
    <DialogActions className={className}>
      <Button
        onClick={() => {
          onLeftActionClick();
          onAccept();
        }}
      >
        {leftActionLabel}
      </Button>
      <Button onClick={onAccept}>{t('OK')}</Button>
    </DialogActions>
  );
}
